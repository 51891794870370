import React, { ReactElement } from 'react';
import './Fireworks.scss';

export function Fireworks(): ReactElement {
  return (
    <div className="pyro">
      <div className="before" />
      <div className="after" />
    </div>
  );
}
