import React, { ReactElement } from 'react';
import { Card as GameCard } from '@double/types';
import { Figure } from './Figure/Figure';
import CardsCss from './Card.module.scss';

interface CardProps {
  card: GameCard;
  size: number;
}

export function Card({ card, size }: CardProps): ReactElement {
  const { figures } = card;

  return (
    <div
      className={CardsCss.card}
      style={{
        width: size,
        height: size,
        transform: `rotate(${card.rotation}deg)`,
      }}
    >
      <div className={CardsCss.cardRow}>
        <Figure size={size} figure={figures[0]} />
        <Figure size={size} figure={figures[1]} />
      </div>
      <div className={CardsCss.cardRow}>
        <Figure size={size} figure={figures[2]} />
        <Figure size={size} figure={figures[3]} />
        <Figure size={size} figure={figures[4]} />
      </div>
      <div className={CardsCss.cardRow}>
        <Figure size={size} figure={figures[5]} />
        <Figure size={size} figure={figures[6]} />
      </div>
    </div>
  );
}
