import React, { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export interface RedirectProps {
  to: string;
}

export function Redirect({ to }: RedirectProps): ReactElement {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(to);
  });

  return <span />;
}
