import React from 'react';
import { Outlet } from 'react-router-dom';
import { io, Socket } from 'socket.io-client';
import { OutletContext } from './common/model/outlet.model';
import './App.scss';

export function App() {
  const socket: Socket<any, any> = io('/games', {
    path: '/socket',
  });

  const context: OutletContext = {
    socket,
  };

  return (
    <Outlet context={[context]} />
  );
}
