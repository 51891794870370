import React, { ReactElement } from 'react';
import CSS from './RoundResult.module.scss';

interface RoundResultProps {
  winner: boolean;
}

export function RoundResult({ winner }: RoundResultProps): ReactElement {
  const file = winner ? 'win' : 'lose';
  const audio = new Audio(`${process.env.PUBLIC_URL}/sounds/${file}.mp3`);
  audio.play();

  return (
    <div className={CSS.container}>
      <span>{winner ? 'You Won!!!' : 'You Lost'}</span>
      <span className={CSS.emoji}>{winner ? '🤗' : '😢'}</span>
    </div>
  );
}
