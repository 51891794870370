import React from 'react';
import { useNavigate } from 'react-router-dom';
import CSS from './GameNotFoundPage.module.scss';

export function GameNotFoundPage() {
  const navigate = useNavigate();

  return (
    <div className={CSS.container}>
      <h1>Game not found</h1>
      <button className="d-btn" type="button" onClick={() => navigate('/')}>Back to home</button>
    </div>
  );
}
