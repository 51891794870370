import React, { ReactElement, useMemo } from 'react';
import { Game } from '@double/types';
import { Card } from './Card/Card';
import CSS from './Round.module.scss';
import { RoundContext } from './Round.context';

export interface RoundProps {
  round: Game;
  onSelected: (id: number) => void;
}

export function Round({ round, onSelected }: RoundProps): ReactElement {
  let roundContextVal;

  useMemo(() => {
    roundContextVal = {
      figureSelected: onSelected,
    };
  }, [onSelected]);

  const { clientWidth: width, clientHeight: height } = document.documentElement;
  const size = Math.min(width * 0.95, height / 2 - 25, 650);

  return (
    <RoundContext.Provider value={roundContextVal}>
      <div className={CSS.container}>
        <Card size={size} card={round.cards[0]} />
        <Card size={size} card={round.cards[1]} />
      </div>
    </RoundContext.Provider>
  );
}
