import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  CreateGameDto, JoinedGameDto, JoinGameDto, GameCreatedDto, GameEvent,
} from '@double/types';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { GamePageInput } from '../GamePage/GamePage.model';
import { OutletContext } from '../common/model/outlet.model';
import CSS from './CreateGamePage.module.scss';

export function CreateGamePage() {
  const navigate = useNavigate();
  const [{ socket }] = useOutletContext<[OutletContext]>();
  const [gameId, setGameId] = useState('');
  const [username, setUsername] = useState(localStorage.getItem('username') || '');

  const updateUsername = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setUsername(target.value);
    localStorage.setItem('username', target.value);
  };

  const createGame = () => {
    socket.emit(GameEvent.Create, { username } as CreateGameDto);
  };

  const joinGame = (id: string) => {
    navigate(`/join/${id}`);
  };

  useEffect(() => {
    socket.on(GameEvent.Created, (dto: GameCreatedDto) => {
      socket.emit(GameEvent.Join, { gameId: dto.gameId, username } as JoinGameDto);
    });

    socket.on(GameEvent.Joined, ({ gameId: joinedGameId, playerId }: JoinedGameDto) => {
      navigate(`${joinedGameId}`, {
        state: {
          username,
          gameId: joinedGameId,
          playerId,
        } as GamePageInput,
      });
    });

    return () => {
      socket.off(GameEvent.Created);
      socket.off(GameEvent.Joined);
    };
  }, []);

  return (
    <div className={CSS.container}>
      <div className={CSS.content}>
        <div>
          <input className="d-input" placeholder="Username" value={username} onChange={updateUsername} />
          <button className="d-btn" type="button" disabled={!username} onClick={createGame}>Create a game</button>
        </div>

        <div>Or</div>

        <div>
          <input
            className="d-input"
            placeholder="Game ID"
            onChange={
              ({ target }) => setGameId(target.value)
            }
          />
          <button
            className="d-btn"
            type="button"
            onClick={() => joinGame(gameId)}
          >
            Join a game
          </button>
        </div>
      </div>
    </div>
  );
}
