import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GameResultDto } from '@double/types';
import CSS from './ResultsPage.module.scss';
import { RoundResult } from '../GamePage/components/RoundResult';
import { Fireworks } from '../common/component/Fireworks/Fireworks';

export interface ResultsPageData extends GameResultDto {
  playerId: string;
}

export function ResultsPage(): React.ReactElement {
  const location = useLocation();
  const navigate = useNavigate();
  const input: ResultsPageData = location.state;

  return (
    <div className={CSS.container}>
      <RoundResult winner={input.playerId === input.winner} />
      <div>
        {`Rounds you win: ${input.winsByPlayer[input.playerId]}`}
      </div>
      <button className="d-btn" type="button" onClick={() => navigate('/game')}>Back to home</button>
      {input.playerId === input.winner && <Fireworks />}
    </div>
  );
}
