import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { GameEvent, JoinedGameDto, JoinGameDto } from '@double/types';
import CSS from './JoinGamePage.module.scss';
import { GamePageInput } from '../GamePage/GamePage.model';
import { OutletContext } from '../common/model/outlet.model';

export function JoinGamePage() {
  const navigate = useNavigate();
  const params = useParams<'gameId'>();
  const [username, setUsername] = useState(localStorage.getItem('username') || '');
  const [{ socket }] = useOutletContext<[OutletContext]>();

  const updateUsername = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(target.value);
    localStorage.setItem('username', target.value);
  };

  const joinRoom = () => {
    socket.emit(GameEvent.Join, { gameId: params.gameId, username } as JoinGameDto);
  };

  useEffect(() => {
    socket.on(GameEvent.Joined, ({ gameId, playerId }: JoinedGameDto) => {
      navigate(`/game/${gameId}`, {
        state: {
          username,
          gameId,
          playerId,
        } as GamePageInput,
      });
    });

    socket.on(GameEvent.JoinError, () => {
      navigate('/gameNotFound');
    });
  }, []);

  return (
    <div className={CSS.container}>
      <h1>Join Game</h1>
      <div className={CSS.content}>
        <input className="d-input" placeholder="Username" value={username} onChange={updateUsername} />
        <button className="d-btn" type="button" onClick={joinRoom} disabled={!username}>Join Game</button>
      </div>
    </div>
  );
}
