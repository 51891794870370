export const GameEvent = {
  Connection: 'connection',
  Disconnect: 'disconnect',
  Namespace: 'games',
  Created: 'game:created',
  Joined: 'game:joined',
  JoinError: 'game:joinError',
  AllJoined: 'game:allJoined',
  RoundStarts: 'game:roundStarts',

  RoundResult: 'game:roundResult',
  GameResult: 'game:gameResult',
  PlayerLeft: 'game:playerLeft',

  Create: 'game:create',
  Join: 'game:join',
  ReadyForRound: 'game:readyForRound',
  GetRound: 'game:getRound',
  SubmitRound: 'game:getRound',
} as const;
