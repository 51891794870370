import React, { ReactElement } from 'react';
import CSS from './LoadingGif.module.scss';

export interface LoadingGifProps {
  label: string;
}

export function LoadingGif({ label }: LoadingGifProps): ReactElement {
  return (
    <div className={CSS.container}>
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <circle cx="50" cy="50" r="45" />
      </svg>
      <span>{label}</span>
    </div>
  );
}
