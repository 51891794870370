import React from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { CreateGamePage } from './CreateGamePage/CreateGamePage';
import { GamePage } from './GamePage/GamePage';
import { Redirect } from './common/component/Redirect';
import { ResultsPage } from './ResultsPage/ResultsPage';
import { App } from './App';
import { JoinGamePage } from './JoinGamePage/JoinGamePage';
import './index.scss';
import { GameNotFoundPage } from './GameNotFoundPage/GameNotFoundPage';

const root = createRoot(
  document.getElementById('root') as HTMLElement,
);

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: 'game',
        element: <CreateGamePage />,
      },
      {
        path: 'game/:gameId',
        element: <GamePage />,
      },
      {
        path: 'gameResult/:gameId/',
        element: <ResultsPage />,
      },
      {
        path: 'join/:gameId/',
        element: <JoinGamePage />,
      },
      {
        path: 'gameNotFound',
        element: <GameNotFoundPage />,
      },
      {
        path: '',
        element: <Redirect to="/game" />,
      },
    ],
  },
  {
    path: '*',
    element: <Redirect to="/game" />,
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
