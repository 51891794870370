import React, { ReactElement, useContext } from 'react';
import { FigureCell } from '@double/types';
import CSS from './Figure.module.scss';
import { RoundContext } from '../../Round.context';

interface FigureProps {
  figure: FigureCell;
  size: number;
}

export function Figure({ figure, size }: FigureProps): ReactElement {
  const { figureSelected } = useContext(RoundContext)!;
  const relativeSize = (size - 20) / 3;
  const figureSize = relativeSize * figure.size;

  const figureStyle = {
    position: 'absolute' as 'absolute',
    transform: `rotate(${figure.rotation}deg)`,
    width: `${figureSize}px`,
    height: `${figureSize}px`,
    top: `${figure.top * figureSize}px`,
    left: `${figure.left * figureSize}px`,
  };

  return (
    <div
      className={CSS.figure}
      style={{
        width: `${relativeSize}px`,
        height: `${relativeSize}px`,
      }}
    >
      <button
        type="button"
        style={figureStyle}
        onClick={() => {
          figureSelected(figure.id);
        }}
      >
        <img
          alt={`figure-${figure.id}`}
          src={`/assets${figure.path}`}
        />
      </button>
    </div>
  );
}
