import React, {
  ReactElement, useEffect, useState, useRef,
} from 'react';
import CSS from './Countdown.module.scss';

export interface CountdownProps {
  startTimeInMillis: number;
  onDone: () => void;
}

export function Countdown({ startTimeInMillis, onDone }: CountdownProps): ReactElement {
  const timeLeftInSec = startTimeInMillis - Date.now();
  const [countdown, setCountdown] = useState(Math.ceil(timeLeftInSec / 1000));
  const countdownRef = useRef(countdown);
  countdownRef.current = countdown;
  let timer: NodeJS.Timeout;

  const func = (interval: number) => {
    timer = setTimeout(() => {
      setCountdown((val) => val - 1);

      if (countdownRef.current > 0) {
        func(1000);
      } else {
        onDone();
      }
    }, interval);
  };

  useEffect(() => {
    const leftover = timeLeftInSec % 1000;
    func(leftover);

    return () => clearTimeout(timer);
  }, []);

  return <div className={CSS.text}>{countdown}</div>;
}
