import React, { ReactElement, useEffect, useState } from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import {
  GameEvent,
  GameResultDto,
  PlayerLeftDto,
  ReadyForRoundDto,
  RoundResultDto,
  RoundStarts,
  SubmitSelectionDto,
} from '@double/types';
import { GamePageInput } from './GamePage.model';
import { Round } from './Round/Round';
import { OutletContext } from '../common/model/outlet.model';
import { LoadingGif } from '../common/component/LoadingGif/LoadingGif';
import { ResultsPageData } from '../ResultsPage/ResultsPage';
import { Countdown } from '../common/component/Countdown/Countdown';
import { RoundResult } from './components/RoundResult';
import CSS from './GamePage.module.scss';

enum PageStatus {
  ROUND_COUNTDOWN = 'ROUND_COUNTDOWN',
  IN_ROUND = 'IN_ROUND',
  WAITING_FOR_OPPONENT = 'WAITING_FOR_OPPONENT',
  SHOW_NEXT_ROUND = 'SHOW_NEXT_ROUND',
}

export function GamePage(): ReactElement {
  const [{ socket }] = useOutletContext<[OutletContext]>();
  const navigate = useNavigate();
  const location = useLocation();
  const input: GamePageInput = location.state;
  const [pageStatus, setPageStatus] = useState<PageStatus>(PageStatus.WAITING_FOR_OPPONENT);
  const [roundData, setRoundData] = useState<RoundStarts>();
  const [roundWinner, setRoundWinner] = useState<string>();

  const startRound = (): void => {
    setPageStatus(PageStatus.WAITING_FOR_OPPONENT);
    socket.emit(GameEvent.ReadyForRound, {
      gameId: input.gameId,
      playerId: input.playerId,
    } as ReadyForRoundDto);
  };

  useEffect(() => {
    startRound();

    socket.on(GameEvent.RoundStarts, (data: RoundStarts) => {
      setRoundData(data);
      setPageStatus(PageStatus.ROUND_COUNTDOWN);
    });

    socket.on(GameEvent.RoundResult, (data: RoundResultDto) => {
      setRoundWinner(data.winner);
      setPageStatus(PageStatus.SHOW_NEXT_ROUND);
    });

    socket.on(GameEvent.GameResult, (data: GameResultDto) => {
      navigate(`/gameResult/${input.gameId}`, {
        state: {
          ...data,
          playerId: input.playerId,
        } as ResultsPageData,
      });
    });

    socket.on(GameEvent.PlayerLeft, (data: PlayerLeftDto) => {
      if (data.gameFinished) {
        alert(`${data.playerThatLeft} has left the game. You won!!!`);
        navigate('/');
      }
    });
  }, []);

  const onSelected = (selectionId: number): void => {
    socket.emit(GameEvent.SubmitRound, {
      gameId: input.gameId,
      playerId: input.playerId,
      selectionId,
    } as SubmitSelectionDto);
    setPageStatus(PageStatus.WAITING_FOR_OPPONENT);
  };

  const showLinkShare = (): void => {
    if (navigator.share) {
      navigator.share({
        text: 'Join me in Double!',
        url: `${window.location.origin}/join/${input.gameId}`,
      })
        .then(() => console.log('Successful shared'));
    } else {
      (navigator as Navigator).clipboard.writeText(`${window.location.origin}/join/${input.gameId}`);
    }
  };

  return (
    <div>
      <div className={CSS.toolbar}>
        <span>{input.username}</span>
        {roundData
          && (
            <span>
              {roundData.currentRound}
              {' '}
              /
              {' '}
              {roundData.totalRounds}
            </span>
          )}
      </div>

      <div className={CSS.mainSection}>
        {pageStatus === PageStatus.ROUND_COUNTDOWN
          && (
            <Countdown
              startTimeInMillis={roundData!.startsAt}
              onDone={() => setPageStatus(PageStatus.IN_ROUND)}
            />
          )}
        {pageStatus === PageStatus.WAITING_FOR_OPPONENT
          && (
            <div>
              <LoadingGif label="Waiting for players" />
              {!roundData
                && (
                  <button
                    className={`${CSS.shareBtn} d-btn`}
                    type="button"
                    onClick={() => showLinkShare()}
                  >
                    {input.gameId}
                  </button>
                )}
            </div>
          )}

        {pageStatus === PageStatus.IN_ROUND
          && <Round round={roundData!.game} onSelected={onSelected} />}
        {pageStatus === PageStatus.SHOW_NEXT_ROUND
          && (
            <div className={CSS.content}>
              <RoundResult winner={roundWinner === input.playerId} />
              <button className="d-btn" type="button" onClick={startRound}>Next Round</button>
            </div>
          )}
      </div>
    </div>
  );
}
